import $ from "jquery"
import jqueryValidate from "jquery-validation";

jqueryValidate($)

const customRules = {
  city: {
    required: true,
    maxlength: 30
  },
  company_name: {
    maxlength: 30,
    minlength: 2
  },
  company: {
    required: true
  },
  commercial_register: {
    required: true
  },
  country: {
    required: true
  },
  description: {
    description: true,
    maxlength: 50
  },
  email: {
    required: true
  },
  first_name: {
    required: true
  },
  last_name: {
    required: true
  },
  message: {
    required: true
  },
  name: {
    required: true
  },
  password: {
    required: true
  },
  password_confirmation: {
    required: true,
    minlength: 8
  },
  phone_number: {
    maxlength: 15
  },
  slug: {
    required: true
  },
  street: {
    required: true
  },
  street_no: {
    required: true
  },
  zip_code: {
    required: true
  }
};

const customMessages = {
  city: {
    required: I18n.t("validations.city.required"),
    maxlength: I18n.t("validations.city.maxlength")
  },
  company: {
    required: I18n.t("validations.company.required")
  },
  company_name: {
    minlength: I18n.t("validations.company.minlength"),
    maxlength: I18n.t("validations.company.maxlength")
  },
  commercial_register: {
    required: I18n.t("validations.commercial_register.required")
  },
  country: {
    required: I18n.t("validations.country.required")
  },
  email: {
    required: I18n.t("validations.email.required")
  },
  first_name: {
    required: I18n.t("validations.first_name.required")
  },
  last_name: {
    required: I18n.t("validations.last_name.required")
  },
  message: {
    required: I18n.t("validations.message.required")
  },
  name: {
    required: I18n.t("validations.name.required")
  },
  password: {
    required: I18n.t("validations.password.required")
  },
  password_confirmation: {
    required: I18n.t("validations.password_confirmation.required"),
    minlength: I18n.t("validations.password.min_length"),
    equalTo: I18n.t("validations.password.equal_to")
  },
  phone_number: {
    maxlength: I18n.t("validations.phone_number.maxlength")
  },
  mobile_phone_number: {
    maxlength: I18n.t("validations.mobile_phone_number.maxlength")
  },
  slug: {
    required: I18n.t("validations.slug.required"),
    uniqueness: I18n.t("validations.slug.uniqueness")
  },
  street: {
    required: "FOO"
  },
  street_no: {
    required: I18n.t("validations.street_no.required")
  },
  zip_code: {
    required: I18n.t("validations.zip_code.required")
  }
};

const applyCustomValidationRules = function($form, customRules) {
  // write custom rules to DOM
  const $validatableInputs = $form.find("[data-validation]");
  for (let elem of Array.from($validatableInputs)) {
    var key, value;
    const $input = $(elem);
    const ruleName = $input.data("validation");
    // map and apply our custom rules
    for (key in customRules[ruleName]) {
      value = customRules[ruleName][key];
      if (($input.data(`rule-${key}`) == null)) { $input.attr(`data-rule-${key}`, value); }
    }
    // use a more specific required message than the default ones if defined
    for (key in customMessages[ruleName]) {
      value = customMessages[ruleName][key];
      if (($input.data(`msg-${key}`) == null)) { $input.attr(`data-msg-${key}`, value); }
    }
  }
  return true;
};

const validateSlug = function() {
  $('#invalid-slug').hide();
  $('#valid-slug').hide();
  $("#uniqueness-slug-error").hide();
  const previousSlug = $('#slugged_search_form_slug').val();
  $('#slugged_search_form_slug').on('keyup', function(event) {
    const url_validator = window.location.protocol + "//" + window.location.host + "/admin/slug_validator/";
    const slug_input = $('#slugged_search_form_slug');
    if((slug_input.val() === "") || (slug_input.val() === previousSlug)) {
      $('#invalid-slug').hide();
      $('#valid-slug').hide();
      $("#uniqueness-slug-error").hide();
    } else {
      $.ajax({
        url: url_validator + slug_input.val(),
        type: 'GET',
        dataType: 'json',
        error(jqXHR, textStatus, errorThrown) {
          return slug_input.addClass("is-invalid");
        },
        success(data, textStatus, jqXHR) {

          if (data.valid === true) {
            $('#valid-slug').show();
            $('#invalid-slug').hide();
            $("#uniqueness-slug-error").hide();
            $("#uniqueness-slug-error").text("");
            return slug_input.removeClass("is-invalid");
          } else if (data.valid === false) {
            $('#valid-slug').hide();
            $('#invalid-slug').show();
            $("#uniqueness-slug-error").show();
            slug_input.addClass("is-invalid");
            return $("#uniqueness-slug-error").text(slug_input.attr("data-msg-uniqueness"));
          }
        }
      });
    }

    event.stopImmediatePropagation();
    return false;
  });
  return false;
};

const validateForm = function(form) {
  applyCustomValidationRules(form, customRules);
  return form.validate({
    onfocusout(input) {
      if ($(input).valid()) {
        return $(input).removeClass("is-invalid");
      }
    },
    errorPlacement($errorLabel, $element) {
      let $inputWrapper;
      if ($element[0].localName === "textarea") {
        $inputWrapper = $element.closest("textarea");
      } else {
        $inputWrapper = $element.closest("input");
      }
      $errorLabel.addClass("invalid-feedback");
      $inputWrapper.closest("div").append($errorLabel);
      return $inputWrapper.addClass("is-invalid");
    }
  });
};

$(document).on('turbolinks:load', function() {
  $('[data-controller="validation"]').each(function() {
    return validateForm($(this));
  });
  validateSlug();
  return $('[data-controller="validate-form"]').on("click", function(e) {
    e.preventDefault();
    const $form = $(this).closest("form");
    if ($form.valid()) {
      return $.ajax({
        method: $(this).data("type"),
        url: $(this).data("url"),
        data: $form.serialize()
      });
    }
  });
});
